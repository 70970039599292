@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@700&family=Courier+Prime&display=swap');

h1 {
  font-family: 'Courier Prime', monospace;
  padding: 0;
  margin: 1.5em;
  font-size: 1.5rem;
}

.container {
  position: relative;
}

.App {
  z-index: 1;
  display: block;
  text-align: center;
}

.App-logo {
  animation: horizontal-shaking 0.05s infinite;
  pointer-events: none;
}

@keyframes horizontal-shaking {
  0% { transform: translateX(0) }
  25% { transform: translateX(5px) }
  50% { transform: translateX(-5px) translateY(1px) }
  75% { transform: translateX(5px) }
  100% { transform: translateX(0) translateY(-1px) }
 }

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#particles-js {
  top: 0;
  left: 0;
  z-index: -1;
  display: inline;
  position: absolute;
  opacity: 0.2;
}